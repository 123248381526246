import React from 'react';
import { useNavigate } from 'react-router-dom';
import backgroundImage from '../assets/IG_background.png';

function Hero() {
  const navigate = useNavigate();

  return (
    <div
      className='text-white flex items-center justify-center h-screen'
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: '#323f4c',
      }}
    >
      <div className='max-w-[1000px] w-full text-center flex flex-col justify-center' style={{ padding: '20px' }}>
        <h1 className='md:text-7xl sm:text-6xl text-4xl font-bold md:py-6' style={{ marginBottom: '20px' }}>
          Taking the Guesswork Out of Wildfire Preparedness
        </h1>
        <p className='md:text-2xl text-xl font-bold text-gray-400' style={{ marginBottom: '20px' }}>
          Analyzing risk on a per parcel basis
        </p>
        <button
          onClick={() => {
            navigate('/register');
          }}
          className='bg-[#FB7A53] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-black'
          style={{ marginTop: '20px', marginBottom: '20px' }}
        >
          Get Started
        </button>
      </div>
    </div>
  );
}

export default Hero;
