import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';

const Signup = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [formValid, setFormValid] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    setFormValid(
      firstName.length > 0 &&
      lastName.length > 0 &&
      email.length > 0 &&
      password.length > 0 &&
      confirmPassword.length > 0 &&
      password === confirmPassword
    );
  }, [firstName, lastName, email, password, confirmPassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const response = await axios.post("https://api.igbackend.com/auth/register", {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password
      });
      if (response.data) {
        navigate("/dashboard");
      }
    } catch (err) {
      setError(err.response.data.message);
    }
  };

  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '100vh', 
      backgroundColor: '#323f4c' 
    }}>
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center', 
        width: '400px', 
        padding: '50px', 
        borderRadius: '15px', 
        backgroundColor: 'white',
        boxSizing: 'border-box'
      }}>
        <h1 style={{ fontWeight: 'bold', marginBottom: '20px', fontSize: '2em' }}>Sign Up</h1>
        <p style={{ marginBottom: '40px' }}>Enter the information below</p>
        {error && <p style={{color: 'red'}}>{error}</p>}
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '20px' }}>
          <input 
            style={{ 
              width: '48%', 
              padding: '10px', 
              borderRadius: '5px', 
              border: '1px solid #000' 
            }} 
            type="text" 
            placeholder="First Name" 
            onChange={(e) => setFirstName(e.target.value)} 
          />
          <input 
            style={{ 
              width: '48%', 
              padding: '10px', 
              borderRadius: '5px', 
              border: '1px solid #000' 
            }} 
            type="text" 
            placeholder="Last Name" 
            onChange={(e) => setLastName(e.target.value)} 
          />
        </div>
        <input 
          style={{ width: '100%', padding: '10px', marginBottom: '20px', borderRadius: '5px', border: '1px solid #000' }} 
          type="email" 
          placeholder="Email" 
          onChange={(e) => setEmail(e.target.value)} 
        />
        <input 
          style={{ width: '100%', padding: '10px', marginBottom: '20px', borderRadius: '5px', border: '1px solid #000' }} 
          type="password" 
          placeholder="Password" 
          onChange={(e) => setPassword(e.target.value)} 
        />
        <input 
          style={{ width: '100%', padding: '10px', marginBottom: '20px', borderRadius: '5px', border: '1px solid #000' }} 
          type="password" 
          placeholder="Confirm Password" 
          onChange={(e) => setConfirmPassword(e.target.value)} 
        />
        {password !== confirmPassword && 
          <p style={{color: 'red'}}>Passwords do not match</p>
        }
        <button 
          style={{ 
            width: '100%', 
            padding: '10px', 
            marginBottom: '20px', 
            borderRadius: '5px', 
            backgroundColor: '#FB7A53', 
            color: 'white', 
            fontWeight: 'bold', 
            border: 'none' 
          }} 
          onClick={handleSubmit}
          disabled={!formValid}
        >
          Sign Up
        </button>
        <Link to="/login" style={{width: '100%'}}>
          <button 
            style={{ 
              width: '100%', 
              padding: '10px', 
              borderRadius: '5px', 
              backgroundColor: '#A9A9A9', 
              color: 'white', 
              fontWeight: 'bold', 
              border: 'none' 
            }}
          >
            Login
          </button>
        </Link>
        <p style={{ 
          textAlign: "center", 
          marginTop: "20px", 
          fontSize: "14px", 
        }}>
          By signing up you agree to our 
          <span style={{
            color: 'blue',
            cursor: 'pointer',
            textDecoration: 'underline'
          }} onClick={() => setShowModal(true)}>
            &nbsp;Terms of Service
          </span>
        </p>
        {showModal && (
          <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px',
            maxHeight: '80%',
            overflowY: 'auto',
            zIndex: 1,
            border: '1px solid #000',
            width: '50%',
            boxSizing: 'border-box'
          }}>
            <button style={{
                position: 'absolute',
                right: '15px',
                top: '10px',
                fontSize: '1.5em',
                color: 'blue',
                cursor: 'pointer',
                backgroundColor: 'transparent',
                border: '2px solid blue',
                borderRadius: '50%',
                padding: '0 5px',
                lineHeight: '1',
              }} onClick={() => setShowModal(false)}>
                &times;
            </button>
            <h2 style={{ fontWeight: 'bold', fontSize: '1.5em', textAlign: 'center', marginBottom: '20px' }}>Terms of Service</h2>
            <p>
InfernoGuard Terms of Service 
 
 
Last Updated: June 1st, 2023 
 
These Terms of Service (“Terms”) govern your access and use of web application made available to you 
by InfernoGuard, LLC (“InfernoGuard”, “we”, “us” or “our”) at infernoguard.co (the “App”) and the 
related wildfire assessment services that are provided through the App (the “Services”).  
 
These Terms are a binding electronic contract between you and InfernoGuard and InfernoGuard provides 
access to the App and Services via the App solely on the terms set forth in these Terms. BY ACCESSING 
THE  APP  OR  OTHERWISE  USING  THE  SERVICES,  YOU  REPRESENT  AND  WARRANT 
THAT:  (I)  YOU  ARE  18  YEARS  OF  AGE  OR  OLDER;  AND  (II)  IF  YOU  REPRESENT  A 
CORPORATION  OR  OTHER  LEGAL  ENTITY,  YOU  WARRANT  AND  REPRESENT  THAT 
YOU HAVE THE RIGHT, POWER, AND AUTHORITY TO ENTER INTO THESE TERMS ON 
BEHALF  OF  SUCH  LEGAL  ENTITY  AND  BIND  IT  TO  THESE  TERMS.  IF  YOU  DO  NOT 
AGREE TO THESE TERMS, DO NOT CREATE AN ACCOUNT OR USE THE SERVICES. 
 
ARBITRATION  NOTICE:    THESE  TERMS  CONTAIN  A  BINDING  ARBITRATION 
PROVISION.  EXCEPT  FOR  CERTAIN  TYPES  OF  DISPUTES  DESCRIBED  IN  THE 
ARBITRATION  CLAUSE  BELOW,  YOU  AGREE  THAT  DISPUTES  BETWEEN  YOU  AND 
INFERNOGUARD  WILL  BE  RESOLVED  BY  MANDATORY  BINDING  ARBITRATION  AND 
YOU  WAIVE  ANY  RIGHT  TO  PARTICIPATE  IN  A  CLASS-ACTION  LAWSUIT  OR  CLASS-
WIDE ARBITRATION. 
 
1. Privacy. The InfernoGuard Privacy Notice explains what personal information we collect through the 
App and Services, why we collect it, how we use it, how we share it, and the controls you have over 
your personal information (if applicable).  Please make sure you reach our Privacy Notice before using 
the App. 
 
2. Modifications to these Terms. We may, at our sole discretion, revise these Terms from time to time. 
The most current version of these Terms will be made available to you within the App. If we make 
material changes to these Terms, we will send you a notification of the changes via email or by posting 
a conspicuous notice in the App. By continuing to access and use the App and Services after such 
changes become effective, you agree to be bound by the revised Terms.  If you do not agree to the 
revised Terms, you must cease use of the App and the Services. 
 
3. Permitted Access and Use.  Subject to your compliance with these Terms, InfernoGuard grants you a 
limited, nonexclusive, non-assignable, non-sublicensable, non-transferable license to access and use 
the App and the Services solely for your own internal purposes and as permitted by these Terms. This 
license is revocable at any time as further contemplated by these Terms.  The Services are designed 
to assist in identifying and managing wildfire risk.  However, we cannot guarantee that use of the 
Services will prevent any wildfires or reduce risk of wildfires.     
 
4. User Account. You must create a user account in order to access the Services (“Account”). You must 
be 18 years of age to maintain an Account. Personal information that we collect during any Account 
registration or through your use of the Account will be used in accordance with our Privacy Notice. 
You must not access the Services using someone else’s credentials. You are responsible for maintaining 
the  confidentiality  of  any  information  you  use  in  connection  with  your  Account,  including  your 
username and password. You are responsible for all activity on your Account. If you believe someone 
else has accessed your Account, please change your password and notify us immediately at the contact 
 
 
information provided below. We may, in our discretion, deny access or block any transaction made 
through your Account without prior notice if we believe the security of your Account, password or 
username has been compromised. 
 
You represent that all information you provide to InfernoGuard upon registration of your Account and 
at all other times while using the Services, including without limitation all property coordinates and 
descriptions, will be true, accurate, current and complete and you agree to update your information as 
necessary to maintain its truth and accuracy. You are responsible for complying with all applicable 
laws, regulations, and policies applicable to your access and use of the App and the Services.  
 
5. Proprietary Rights; Wildfire Risk Assessments. As between you and InfernoGuard, all right, title, 
and interest in the App and all materials contained in the App or generated by use of the Services, 
including trademarks, logos, documentation,  notifications, text, graphics, images, computer code, data, 
reports,  notes,  deliverables,  illustrations,  designs,  icons,  photographs,  and  written  and  all  other 
materials as well as their selection and arrangement, and all intellectual property rights in the foregoing, 
are  exclusively  the  property  of  InfernoGuard  or,  as  applicable,  its  vendors  and  licensors. 
Notwithstanding the foregoing, InfernoGuard grants you a non-exclusive, limited, perpetual license to 
make reasonable personal use of the wildfire risk assessments generated by your use of the Services. 
Except for the rights expressly granted to you in these Terms, InfernoGuard reserves all rights in and 
to the App and the Services.  
 
 
6. Prohibited Uses. In addition to the other restrictions outlined in these Terms, you agree that you will 
not: 
 
• Use the App or the Services for any purpose that is illegal, unauthorized, beyond the scope of its 
intended use, or engage in, encourage, promote, advocate or assist any third party in any activity 
that violates these Terms or is otherwise prohibited in these Terms; 
• Interfere with or disrupt servers or networks used by InfernoGuard to provide the App or Services 
or violate any third-party regulations, policies or procedures of such servers or networks or harass 
or interfere with another user's full use and enjoyment of the App and Services; 
• Intercept, examine, monitor or otherwise observe any proprietary communications protocol used by 
a device, a client or a server communicating with the App, whether through the use of a network 
analyzer, packet sniffer or other device or software, unless expressly authorized by InfernoGuard;  
• Use any robot, bot, spider, crawler, scraper, or other automated means or interface not provided by 
InfernoGuard to access the App or to extract data; 
• Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code 
for any underlying software or other intellectual property used to provide the App or the Services, 
or any component thereof, or circumvent measures employed to prevent or limit access to any area, 
content, or source code of the App; 
• Use or attempt to use another user’s account without authorization or otherwise impersonate any 
person or entity or otherwise misrepresent your affiliation with a person or entity; 
• Attempt  to  compromise  any  security  measures  or  circumvent  any  content-filtering  techniques 
InfernoGuard employs, or attempt to access areas or features of the App that you are not authorized 
to access; 
• Represent in any manner that you have a relationship of any kind with us or that InfernoGuard has 
endorsed you or any products or services without our express written consent to do so; 
• Reproduce, duplicate, copy, buy, sell, trade, resell or exploit for any purpose any portion of the App 
or the Services without our prior written consent; and 
 
 
• Develop any third-party applications that interact with the App or Services without prior written 
consent from InfernoGuard. 
 
7. Fees. There is no free version of the App or Services and access to your Account and Services is based 
on your payment upon registration. You will pay a one-time payment upon creating your Account, 
where you will provide all requested information about the applicable property for which Services are 
being procured. All fees paid for the Services are non-refundable and non-cancellable.  Fees may 
change from time to time, in which case we will provide notice to you. We use a third-party payment 
processor,  Stripe,  to  process  payments.  Stripe’s  terms  of  service  are  available  here: 
https://stripe.com/legal/end-users. We have no liability if your payment card is rejected or denied by 
the payment processor for any reason.   
 
8. Feedback.  You agree that submission of feedback, suggestions, ideas, or other information or materials 
regarding  InfernoGuard,  the  App  or  Services  that  you  provide,  whether  by  email  or  otherwise 
(“Feedback”)  is  at  your  own  risk  and  that  InfernoGuard  has  no  obligations  (including  without 
limitation obligations of confidentiality) with respect to such Feedback. You represent and warrant that 
you have all rights necessary to submit the Feedback. You hereby grant to InfernoGuard a fully paid, 
royalty-free,  perpetual,  irrevocable,  worldwide,  non-exclusive,  and  fully  sub-licensable  right  and 
license to use, reproduce, perform, display, distribute, adapt, modify, re-format, create derivative works 
of, and otherwise commercially or non-commercially exploit in any manner, any and all Feedback, and 
to sub-license the foregoing rights, in connection with the operation and maintenance of the App or 
provision of Services. 
 
9. Termination. These Terms will commence upon activation of your Account and will continue until 
you cease all access to the App and the Services.  InfernoGuard may, for any reason and in its sole 
discretion, suspend or terminate your Account or your access to or use of the App, and may terminate 
these Terms, without notice or liability, including if: (i) you breach these Terms or if we suspect you 
have breached these Terms; (ii) there is any unauthorized use of your Account credentials; (iii) you act 
in a manner inconsistent with applicable laws or regulations; (iv) required by law; or (v) it becomes no 
longer commercially viable to provide the App. In the event you terminate your Account, your access 
to the App and the Services will cease immediately. If we suspend or terminate your Account, you may 
not create another Account without our prior written permission. InfernoGuard will not be liable to you 
or anyone else for any damages arising from or related to InfernoGuard’s suspension or termination of 
your  access  to  your  Account,  the  App,  or  the  Services,  or  in  the  event  InfernoGuard  modifies, 
discontinues, or restricts the availability of your Account, the App, or the Services (in whole or in part) 
as contemplated by these Terms. 
 
10. Disclaimer of Warranties. THE APP AND THE SERVICES ARE PROVIDED "AS IS", "AS AVAILABLE" AND 
"WITH ALL FAULTS". TO THE FULLEST EXTENT PERMISSIBLE BY LAW,  INFERNOGUARD, TOGETHER 
WITH OUR INVESTORS, SHAREHOLDERS, OFFICERS, DIRECTORS, EMPLOYEES, CONTRACTORS, SERVICE 
PROVIDERS  OR  AGENTS  (COLLECTIVELY,  THE  “INFERNOGUARD  PARTIES”)  DISCLAIM  ALL 
WARRANTIES,  EXPRESS  OR  IMPLIED,  INCLUDING,  BUT  NOT  LIMITED  TO,  THE  WARRANTIES  OF 
MERCHANTABILITY,  FITNESS  FOR  A  PARTICULAR  PURPOSE,  NON-INFRINGEMENT,  TITLE,  CUSTOM, 
TRADE, QUIET ENJOYMENT, SYSTEM INTEGRATION AND FREEDOM FROM COMPUTER VIRUS.  
 
THE INFERNOGUARD PARTIES DO NOT REPRESENT OR WARRANT (A) AS TO THE AVAILABILITY OR 
USEFULNESS  OF  THE  APP  OR  THE  SERVICES;  (B)  THAT  THE  APP  WILL  BE    ERROR-FREE  OR 
UNINTERRUPTED; (C) THAT DEFECTS WILL BE CORRECTED; (D) THAT THE APP OR THE SERVER THAT 
MAKES  THE  APP  AVAILABLE  IS  FREE  FROM  ANY  HARMFUL  COMPONENTS,  INCLUDING,  WITHOUT 
LIMITATION, VIRUSES; OR (E) THE INFORMATION (INCLUDING ANY INSTRUCTIONS) ON THE APP IS 
ACCURATE, COMPLETE, OR USEFUL.  
 
 
 
THE  INFERNOGUARD  PARTIES  MAKE  NO  WARRANY  OR  REPRESENTATION  THAT  THE 
INFORMATION  CONTAINED  IN  THE  APP  OR  THROUGH  THE  SERVICES,  INCLUDING  ANY 
ASSESSMENTS, IS GUARANTEED TO REDUCE, REMEDIATE, OR ELIMINATE FIRE OR RELATED RISKS, 
OR IN ANY WAY ARE GUARANTEED TO DETECT OR NOTIFY YOU OF FIRES. YOU ACKNOWLEDGE 
THAT YOUR USE OF THE APP AND SERVICES IS AT YOUR SOLE RISK.  
 
THE INFERNOGUARD PARTIES DO NOT WARRANT THAT YOUR USE OF THE APP IS LAWFUL IN ANY 
PARTICULAR  JURISDICTION,  AND  THE  INFERNOGUARD  PARTIES  SPECIFICALLY  DISCLAIM  SUCH 
WARRANTIES. SOME JURISDICTIONS LIMIT OR DO NOT ALLOW THE DISCLAIMER OF IMPLIED OR OTHER 
WARRANTIES  SO  THE  ABOVE  DISCLAIMER  MAY  NOT  APPLY  TO  YOU  TO  THE  EXTENT  SUCH 
JURISDICTION'S LAW IS APPLICABLE TO YOU AND THESE TERMS. 
 
11. Indemnification.  BY AGREEING TO THESE TERMS YOU AGREE TO INDEMNIFY, DEFEND, AND HOLD 
HARMLESS ANY AND ALL INFERNOGUARD PARTIES FROM AND AGAINST ANY AND ALL CHARGES, 
CLAIMS,  DAMAGES,  LOSSES,  COSTS,  LIABILITIES,  AND  EXPENSES  (INCLUDING  ATTORNEYS’  FEES) 
INCURRED AS A RESULT OF OR ARISING FROM ANY CLAIM, COMPLAINT, ALLEGATION, LAWSUIT OR 
DEMAND ARISING OUT OF OR RELATING IN ANY WAY TO (A) YOUR ACCESS TO OR USE OF THE APP, 
SERVICES OR YOUR ACCOUNT; (B) YOUR VIOLATION OF THESE TERMS; AND (C) YOUR VIOLATION OF 
ANY APPLICABLE LAW. 
 
12. Limitation of Liability. EXCEPT WHERE PROHIBITED BY LAW, IN NO EVENT WILL INFERNOGUARD OR 
THE  INFERNOGUARD  PARTIES  BE  LIABLE  FOR  ANY  INDIRECT,  SPECIAL,  INCIDENTAL,  PUNITIVE, 
INCIDENTAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES THAT RESULT FROM (A) THE USE OF, OR 
INABILITY TO USE, THE APP; (B) THE PROVISION OF THE APP OR THE SERVICES, INCLUDING WILDFIRE 
RISK ASSESSMENTS AND NOTIFICATIONS, OR THE LACK THEREOF; OR (C) ANY DELAY OR INABILITY TO 
USE THE APP OR THE SERVICES, EVEN IF INFERNOGUARD HAS BEEN ADVISED OF THE POSSIBILITY OF 
SUCH DAMAGES OR SUCH DAMAGES ARE FORESEEABLE.  
 
YOU ASSUME TOTAL RESPONSIBILITY FOR YOUR USE OF THE APP. YOUR ONLY REMEDY AGAINST 
INFERNOGUARD FOR DISSATISFACTION WITH THE APP OR THE SERVICES IS TO STOP USING THE SAME. 
NOTWITHSTANDING THE FOREGOING, IN NO EVENT WILL INFERNOGUARD’S LIABILITY TO YOU FOR 
ANY DAMAGE OR LOSS THAT ARISES OUT OF OR IS IN ANY WAY CONNECTED WITH THESE TERMS OR 
YOUR USE OF THE APP OR THE SERVICES, EXCEED THE TOTAL FEES PAID BY YOU TO INFERNOGUARD IN 
THE PREVIOUS SIX (6) MONTHS.  THE FOREGOING LIMITATIONS APPLY EVEN IF ANY STATED REMEDY 
SHOULD FAIL OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS OF 
LIABILITY, SO THE FOREGOING LIMITATION MAY NOT APPLY TO YOU. 
 
13. Third-Party  Content. The App may contain links to third-party websites or resources or content. 
InfernoGuard makes no guarantee and has no obligation as to the accuracy, timeliness, completeness, 
usefulness, or integrity of third-party content. InfernoGuard does not control or endorse and is not 
responsible or liable for any such third-party content. You are solely responsible for your use of any 
such third-party content. Third-party apps and sites may have their own legal terms and conditions and 
you may be giving others permission to use your contact information or other information by using or 
interacting with them. 
 
14. Your Access to the Sites. The App is controlled by InfernoGuard from within the United States of 
America. InfernoGuard makes no representation that the Services are appropriate or available for use 
in other countries. Those who choose to access the App from locations outside of the United States do 
so on their own initiative and are responsible for compliance with applicable local laws.  
 
 
 
15. Arbitration Agreement; Class Waiver; Waiver of Trial by Jury 
 
PLEASE  READ  THE  FOLLOWING  PARAGRAPHS  CAREFULLY  BECAUSE  THEY  REQUIRE  YOU  TO 
ARBITRATE DISPUTES WITH INFERNOGUARD AND LIMIT THE MANNER IN WHICH YOU CAN SEEK 
RELIEF FROM US. 
a. Applicability of Arbitration Agreement 
    All claims and disputes between the parties that cannot be resolved informally must be resolved by 
binding  arbitration  on  an  individual  basis  under  the  terms  set  forth  below  (the  “Arbitration 
Agreement”).  Notwithstanding the foregoing, in the event of any actual, alleged or threatened 
violation of confidentiality or violation of the other party’s intellectual property or other proprietary 
rights, the non-breaching party may immediately resort to court proceedings in a court of competent 
jurisdiction as set forth below in order to seek immediate equitable relief without posting of a bond, 
proof of damages or other similar requirement.    
    This Arbitration Agreement applies to you and InfernoGuard, and to any subsidiaries, affiliates, 
agents, employees, predecessors in interest, successors, and assigns, as well as all authorized or 
unauthorized users or beneficiaries of the App or Services provided under the Terms. 
The  arbitration  proceeding  will  be  administered  by  the  International  Chamber  of  Commerce 
(“ICC”) pursuant to its Rules of Arbitration before an arbitrator selected by and pursuant to the 
rules of the ICC. Any final award may be filed and enforced in any court of competent jurisdiction. 
The parties shall share equally in the costs assessed for the arbitration.  Any such arbitration will 
take place at a location within the United States federal judicial district identified below in Section 
17 and will apply the substantive law set forth therein.  Any arbitration proceeding may not be 
consolidated or joined with any other proceeding and will not proceed as a class action.  THE 
PARTIES  UNDERSTAND  THAT  THEY  WOULD  HAVE  HAD  A  RIGHT  OR 
OPPORTUNITY TO LITIGATE DISPUTES THROUGH A COURT, TO HAVE A JUDGE 
OR  JURY  DECIDE  THEIR  CASE,  AND  TO  PARTICIPATE  IN  A  CLASS  ACTION  OR 
OTHER PROCEEDING INVOLVING MULTIPLE CLAIMANTS, BUT THEY CHOOSE 
TO HAVE ANY DISPUTES DECIDED THROUGH INDIVIDUAL ARBITRATION.  
The decision of the arbitrator will be a final and binding resolution of the dispute. This 
Arbitration Agreement is made pursuant to a transaction involving interstate commerce and will 
be governed by the Federal Arbitration Act, 9 U.S.C. Sections 1–16. Judgment upon the award 
may be entered in any court having jurisdiction.  
b.   Survival 
    This provision will survive the termination of your relationship with InfernoGuard. 
c. Time Limitation on Claims 
You  agree  that  any  claim  you  may  have  arising  out  of  or  related  to  your  relationship  with 
InfernoGuard must be filed within one (1) year after such claim arose; otherwise, your claim is 
permanently barred. 
     
16. Applicable Law. Any dispute between you and InfernoGuard will be governed by the laws of the State 
of Illinois and applicable United States law, without giving effect to any conflict-of-laws principles that 
may  provide  for  the  application  of  the  law  of  another  jurisdiction.  Unless  otherwise  required  by 
 
 
applicable law, and except as provided under Section 16 (Arbitration), venue is exclusively in the state 
or federal courts, as applicable, located in Cook County, Illinois. Any cause of action or other claim 
brought by you with respect to the App or Services must be commenced within one (1) year after the 
cause of action or claim arises 
 
17. Assignment.  You may not assign your rights or delegate your duties under these Terms, including 
without  limitation,  your  Account,  without  prior  consent.  These  Terms  inure  to  the  benefit  of 
InfernoGuard’s successors and assigns.  
 
18. Severability.  If  any  provision  of  these  Terms  shall  be  deemed  unlawful,  void  or  for  any  reason 
unenforceable, then that provision shall be deemed severable from these Terms and shall not affect the 
validity and enforceability of any remaining provisions. 
 
19. Electronic Communications. You acknowledge that these Terms are a valid and binding agreement.  
To  the  fullest  extent  permitted  by  law,  you  agree  that  these  Terms  and  any  other  documentation, 
agreements,  notices  or  communications  between  you  and  InfernoGuard  may  be  provided  to  you 
electronically.  
 
20. Entire Agreement. These Terms supersede all prior understandings regarding the App and represent 
the complete agreement between you and InfernoGuard. These Terms do not create or confer any third-
party beneficiary rights. We encourage you to frequently review the Terms to ensure you understand 
the terms and conditions that apply to your use of the App, the Services or your Account. 
 
21. Contact Us. If you have questions or concerns regarding these Terms, contact us at: 
 
InfernoGuard 
Email: admin@infernoguardusa.com 
1 East Erie #525-5029 
Chicago, IL 60611 
 
   </p>
            <button 
              style={{ 
                width: '100%', 
                padding: '10px', 
                marginTop: '20px',
                borderRadius: '5px', 
                backgroundColor: '#FB7A53', 
                color: 'white', 
                fontWeight: 'bold', 
                border: 'none' 
              }} 
              onClick={() => setShowModal(false)}
            >
              Accept
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Signup;
